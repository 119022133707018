import config from 'config';

export const getLoginUrl = () =>
  `/oauth/authorize?dest=${encodeURI(window.location.href)}`;

export const getLogoutUrl = () =>
  `/oauth/logout?dest=${encodeURI(window.location.href)}`;

export const getRegisterUrl = () =>
  `${config.AUTH_URL}/register?dest=${encodeURI(window.location.href)}`;
