import { FlexBox, Position, Rhythm } from '@trmediaab/zebra';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { ROUND_LINKS } from '../constants';
import { desktopItems, extendedItemsMap, sideItems } from '../items';
import { GenerativeLink, LinkItem } from '../utils';

const UserMenu = ({ children, closeMenu, rounds }) => (
  <Position.fixed
    top="0"
    height="100%"
    width="100%"
    bg="white"
    zIndex="9999999"
    overflow="auto"
    css={`
      scrollbar-width: none;
      -ms-overflow-style: none;
      -webkit-overflow-scrolling: touch;
      ::-webkit-scrollbar {
        display: none;
      }
    `}
  >
    {children}
    <Rhythm between="2" display="flex" flexDirection="column" p="3">
      {desktopItems.map(({ text, to }, index) => (
        <Fragment key={index}>
          <LinkItem key={text} to={to} fontSize="3" onClick={closeMenu}>
            {text}
          </LinkItem>
          {extendedItemsMap.hasOwnProperty(text) && (
            <Rhythm between="1" display="flex" flexDirection="column" ml="3">
              {extendedItemsMap[text].map(({ text: childText, to }) =>
                ROUND_LINKS.includes(text) ? (
                  <GenerativeLink
                    text={childText}
                    key={childText}
                    rounds={rounds}
                    selectedRound={text}
                    fontSize="2"
                    onClick={closeMenu}
                    setSelectedRound={text}
                  />
                ) : (
                  <LinkItem key={text} to={to} fontSize="2" onClick={closeMenu}>
                    {text}
                  </LinkItem>
                ),
              )}
            </Rhythm>
          )}
        </Fragment>
      ))}
    </Rhythm>
    <FlexBox display="flex" flexDirection="column" pl="3" pb="3">
      {sideItems.map(({ text, to }, index) => (
        <LinkItem key={index} to={to} fontSize="2" onClick={closeMenu}>
          {text}
        </LinkItem>
      ))}
    </FlexBox>
  </Position.fixed>
);

UserMenu.propTypes = {
  children: PropTypes.node.isRequired,
  closeMenu: PropTypes.func.isRequired,
  rounds: PropTypes.array.isRequired,
};

export default UserMenu;
