import { Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectPremium } from 'containers/Auth/selectors';
import { getCampaigns } from 'containers/Promo/selectors';

import config from '../../config';
import { PromoLink, StyledPromo } from './styles';

const shouldShowPromo = (premium_access, location) =>
  config.PREMIUM_TIER_ENABLED &&
  !premium_access &&
  !['PRODUCTS_PAGE', 'CHECKOUT_PAGE'].includes(location.type);

const Promo = ({ campaign, premium_access, location }) => {
  const promo = campaign?.results?.[0];

  if (!shouldShowPromo(premium_access, location) || promo == null) {
    return null;
  }

  const {
    headline,
    links: [link],
  } = promo;

  return (
    <StyledPromo loaded>
      <Fragment>
        <span>{headline}</span>
        {link != null && (
          <PromoLink as="a" href={link.url}>
            {link.text}
          </PromoLink>
        )}
      </Fragment>
    </StyledPromo>
  );
};

export default connect(
  createStructuredSelector({
    campaign: getCampaigns,
    premium_access: selectPremium,
    location: state => state.location,
  }),
)(Promo);
