export const WAIT_FOR_ALL_AD_SLOTS = 500;

export const STATUS = {
  INITIAL: 'INITIAL',
  FILLED: 'FILLED',
  EMPTY: 'EMPTY',
};

const inFeedSizeMappings = [
  {
    viewport: [0, 0],
    sizes: [[320, 320]],
  },
  {
    viewport: [1010, 0],
    sizes: [[980, 240]],
  },
];

const panoramaSizeMapping = [
  {
    viewport: [0, 0],
    sizes: [[320, 320]],
  },
  {
    viewport: [1010, 0],
    sizes: [[980, 240]],
  },
];

const skyscraperSizeMapping = [
  {
    viewport: [0, 0],
    sizes: [[300, 600]],
  },
];

export const UNIT_CONFIGS = {
  panorama: panoramaSizeMapping,
  feed1: inFeedSizeMappings,
  feed2: inFeedSizeMappings,
  feed3: inFeedSizeMappings,
  sticky: skyscraperSizeMapping,
};

export const PLACEMENTS = {
  panorama: 'Panorama_1',
  feed1: 'Panorama_2',
  feed2: 'Panorama_3',
  feed3: 'Panorama_4',
  sticky: 'Sticky',
};

export const ADS_PREFIX = 'RONDEN_SPEL';
