import { stringify } from 'query-string';

import truth from '../containers/Auth/truth';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const contentType = response.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) {
    return response.json().then(json => {
      const error = new Error(response.statusText);
      error.response = response;
      error.jsonMessage = json; // message from server
      throw error;
    });
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

// build request URL
/*
 endpoint: endpoint name
 args: arguments in query url
 */
export function apiUrl(endpoint = '', args = {}, apiName = 'trais-api') {
  const query = {};

  let url;

  if (apiName === 'trais-api') {
    url = `/api/v1/public/${endpoint}/`;
  } else if (apiName === 'tsp-api') {
    url = `/api/${endpoint}/`;
  } else {
    throw new Error(`Unknown apiName ${apiName}`);
  }

  if (args != null) {
    Object.keys(args).forEach(name => {
      const value = args[name];

      if (typeof args[name] === 'object') {
        //  value = JSON.stringify(value);
      }
      // prevent empty parameters
      if (value !== '' && value !== null) {
        query[name] = value;
      }
    });

    if (Object.keys(query).length > 0) {
      url = `${url}?${stringify(query, false)}`;
    }
  }

  return url;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function request(url, opts = {}, auth = true) {
  function makeRequest({ refresh }) {
    const options = opts;
    const { jwtToken, csrfToken } = truth;

    if (!options.headers) {
      options.headers = {};
    }

    options.headers.SITE = options.headers.SITE ?? 'travronden_spel';
    options.headers.Accept = 'application/json';

    if (auth) {
      if (jwtToken != null) {
        options.headers.Authorization =
          jwtToken == null ? '' : `JWT ${jwtToken}`;
      }
      if (csrfToken != null) {
        options.headers['X-CSRFToken'] = csrfToken == null ? '' : csrfToken;
        options.headers['X-JWT-CSRF-Token'] =
          csrfToken == null ? '' : csrfToken;
      }
      // No cookies
      options.credentials = 'include';
    }

    return fetch(url, options).then(response => {
      if (refresh && response.status === 401) {
        return (
          truth
            .refreshDebounced()
            // Try to make the request again regardless of whether the refresh
            // succeeded.
            // 1. The user’s tokens get messed up.
            // 2. That causes 401 for _all_ API requests (that’s how Django
            //    REST Framework works).
            // 3. The refresh fails (the user isn’t logged in anymore).
            // 4. But the bad tokens have been cleared, so the requests should
            //    succeed now.
            .then(
              () => makeRequest({ refresh: false }),
              () => makeRequest({ refresh: false }),
            )
        );
      }
      return response;
    });
  }
  return makeRequest({ refresh: true }).then(checkStatus).then(parseJSON);
}
