import createReducer from '@ikhsaan/create-reducer';
import { localStorage } from '@trmediaab/zebra-utils';
import Immutable from 'seamless-immutable';

import { LIVE_FEED_VISIT } from './constants';

const items = localStorage.get('live_feed_visit');

const initialState = Immutable({
  live_feed_visit: items || {},
});

const Reducer = createReducer(initialState, {
  [LIVE_FEED_VISIT](state, { slug }) {
    const newState = state.setIn(['live_feed_visit', slug], new Date());

    localStorage.set('live_feed_visit', newState.live_feed_visit.asMutable());
    return newState;
  },
});

export default Reducer;
