import { Box } from '@trmediaab/zebra';
import { useWindowEvents } from '@trmediaab/zebra-hooks';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';

import { getWindowSize } from '../../utils/dom';
import { useAdsLoaderContext } from './AdsLoaderContext';
import { STATUS } from './constants';
import useDfpSlot from './useDfpSlot';

const getCssHeight = (sizes, sizeMapping) => {
  if (sizes == null && sizeMapping == null) {
    return;
  } else if (sizeMapping == null) {
    const [prop, value] = sizes.reduce(
      (acc, [_, h]) => {
        // Set min-height only if there are different heights in the size array
        acc[0] = acc[1] != null && acc[1] !== h ? 'min-height' : 'height';
        // Set the min-height or height value to the lowest height in the size array
        acc[1] = acc[1] == null || h < acc[1] ? h : acc[1];

        return acc;
      },
      ['', undefined],
    );
    let css = `${prop}: ${value}px;`;
    if (prop === 'min-height') {
      css = `${css} height: auto;`;
    }
    return css;
  } else {
    const css = sizeMapping
      .reduce((acc, { viewport, sizes }) => {
        const heightRule = getCssHeight(sizes);
        if (viewport[0] === 0) {
          acc.push(heightRule);
        } else {
          acc.push(`
          @media (min-width: ${viewport[0]}px) {
            ${heightRule}
          }
        `);
        }
        return acc;
      }, [])
      .join('');

    return css;
  }
};

const Slot = ({
  name,
  sizes,
  sizeMapping,
  renderOutOfThePage,
  onStatusChange,
  refreshKey,
  ...slotProps
}) => {
  const node = useRef(null);
  const windowSize = useRef(getWindowSize());
  const cssHeight = useRef(getCssHeight(sizes, sizeMapping));
  const lastRefreshKey = useRef(refreshKey);
  const { refreshSlotId } = useAdsLoaderContext();

  const scaleFn = useCallback(() => {
    if (node.current == null || sizeMapping == null) {
      return;
    }
    const adWidth = node.current.clientWidth;
    const adHeight = node.current.clientHeight;
    const availableWidth = node.current.parentNode.clientWidth;
    const scale = adWidth > availableWidth ? availableWidth / adWidth : 1;
    node.current.style.transform = `scale(${scale})`;
    node.current.style.marginBottom = `${adHeight * (1 - scale) * -1}px`;
  }, [sizeMapping]);

  const onResize = useCallback(() => {
    if (
      sizeMapping != null &&
      windowSize.current?.width !== getWindowSize().width
    ) {
      windowSize.current = getWindowSize();
      scaleFn();
    }
  }, [sizeMapping, scaleFn]);

  const onRender = useCallback(
    event => {
      const status = event.isEmpty ? STATUS.EMPTY : STATUS.FILLED;
      if (status === STATUS.FILLED) {
        scaleFn();
      }

      onStatusChange(status);
    },
    [onStatusChange, scaleFn],
  );

  useWindowEvents('resize', onResize);

  const slotId = useDfpSlot({
    path: `${process.env.REACT_APP_DFP_NETWORK_ID}/${name}`,
    sizes,
    sizeMapping,
    onSlotRender: onRender,
    renderOutOfThePage,
  });

  useEffect(() => {
    if (lastRefreshKey.current !== refreshKey) {
      refreshSlotId(slotId);
      lastRefreshKey.current = refreshKey;
    }
  }, [slotId, refreshKey, refreshSlotId]);

  return (
    <Box
      ref={node}
      display="table"
      css={`
        transform-origin: left top;
        ${cssHeight.current}
      `}
    >
      {slotId && <Box id={slotId} {...slotProps} />}
    </Box>
  );
};

Slot.propTypes = {
  name: PropTypes.string.isRequired,
  sizes: PropTypes.array,
  sizeMapping: PropTypes.array,
  renderOutOfThePage: PropTypes.bool,
  onStatusChange: PropTypes.func.isRequired,
  refreshKey: PropTypes.string,
};

export default Slot;
