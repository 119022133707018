import { FlexBox } from '@trmediaab/zebra';
import PropTypes from 'prop-types';

import UserItems from './UserItems';

const UserNavigation = ({ user, hasPremium }) => (
  <FlexBox width="100%" display={['block', null, 'none']}>
    <UserItems user={user} hasPremium={hasPremium} />
  </FlexBox>
);

UserNavigation.propTypes = {
  user: PropTypes.object,
  hasPremium: PropTypes.bool,
};

export default UserNavigation;
