import {
  FETCH_FEED_FAILURE,
  FETCH_FEED_STARTED,
  FETCH_FEED_SUCCESS,
  FETCH_OLDER_RESULTS,
  SHOW_NEW_RESULTS,
} from './constants';

export const fetchFeedStarted = isOlder => ({
  type: FETCH_FEED_STARTED,
  isOlder,
});

export const fetchFeedSuccess = response => ({
  type: FETCH_FEED_SUCCESS,
  response,
});

export const fetchFeedFailure = (error, isOlder) => ({
  type: FETCH_FEED_FAILURE,
  error,
  isOlder,
});

export const showNewResults = () => ({
  type: SHOW_NEW_RESULTS,
});

export const fetchOlderResults = () => ({
  type: FETCH_OLDER_RESULTS,
});
