import PropTypes from 'prop-types';
import { Fragment } from 'react';

import Error from '../Error';
import LoadingIndicator from '../LoadingIndicator';

const Spinner = props => {
  let ChildComponent;
  if (props.error) {
    return <Error error={props.error} />;
  } else if (props.loading) {
    return <LoadingIndicator />;
  } else {
    ChildComponent = props.component;
  }

  return (
    <Fragment>
      {ChildComponent && <ChildComponent {...props} />}
      {!ChildComponent && <Fragment>{props.children}</Fragment>}
    </Fragment>
  );
};

Spinner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
  ]),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default Spinner;
