const REFRESH_TIMEOUT = 100; // ms

class AuthClient {
  constructor() {
    this.refreshPromise = undefined;
    this.csrfToken = undefined;
    this.user = undefined;
  }

  refresh() {
    return fetch('/oauth/validate', {
      credentials: 'include',
    })
      .then(response => Promise.all([response.status, response.json()]))
      .then(([responseStatus, responseData]) => {
        if (responseStatus < 200 || responseStatus >= 300) {
          const error = new Error(responseData.message || 'Unknown error');
          error.status = responseStatus;
          throw error;
        }
        return responseData;
      })
      .then(responseData => {
        this.csrfToken = responseData.csrf_token;
        this.user = responseData.user;

        return responseData;
      });
  }

  // On many pages we make many API requests at the same time. Debouncing makes
  // it possible to only make one refresh request instead of one per API
  // request.
  refreshDebounced() {
    if (this.refreshPromise != null) {
      return this.refreshPromise;
    }

    this.refreshPromise = new Promise(resolve =>
      setTimeout(resolve, REFRESH_TIMEOUT),
    )
      .then(() => this.refresh())
      .then(
        response => {
          this.refreshPromise = undefined;
          return response;
        },
        error => {
          this.refreshPromise = undefined;
          throw error;
        },
      );

    return this.refreshPromise;
  }
}

const truthClient = new AuthClient();

export default truthClient;
