import { createGenericSelector } from '../GenericFetch/selectors';
import { NAMESPACE } from './constants';

const getKey = props => `${props.slug}-${props.leg}`;

export const selectRoundLeg = createGenericSelector(NAMESPACE, 'data', getKey);
export const selectError = createGenericSelector(NAMESPACE, 'error', getKey);
export const selectLoading = createGenericSelector(
  NAMESPACE,
  'fetching',
  getKey,
);
