export const NAMESPACE = 'auth';
export const INIT_APP = 'INIT_APP';
export const AUTH_REFRESH = 'auth/AUTH_REFRESH';
export const AUTH_SUCCESS = 'auth/AUTH_SUCCESS';
export const RESET_STATE = 'auth/RESET_STATE';
export const FETCH_OFFERS = 'auth/FETCH_OFFERS';

export const initApp = () => ({
  type: INIT_APP,
});

export const authRefresh = () => ({
  type: AUTH_REFRESH,
});

export const authRequestSuccess = user => ({
  type: AUTH_SUCCESS,
  user,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const fetchOffers = () => ({
  type: FETCH_OFFERS,
});
