import PropTypes from 'prop-types';
import styled from 'styled-components';

import logo from 'images/travronden-spel.svg';
import Bugsnag from 'utils/bugsnag';
import { colors, spacing } from 'utils/css-mixins';

const BugsnagErrorBoundary = Bugsnag.getPlugin('react');

const Wrapper = styled.div`
  min-height: ${props => (props.coverFullScreen ? '100vh' : '100%')};
  min-width: ${props => (props.coverFullScreen ? '100vw' : '100%')};
  display: flex;
  justify-content: center;
  align-items: ${props => (props.coverFullScreen ? 'center' : 'start')};
  background-color: ${props =>
    props.coverFullScreen ? colors.primary : undefined};
  color: ${props => (props.coverFullScreen ? colors.white : undefined)};
`;

const Content = styled.div`
  margin: ${spacing.siteGutter}px;
  line-height: 1.625;
  text-align: center;

  h1 {
    line-height: 1.333;
  }

  a,
  button {
    color: ${colors.orange};
    font-weight: 600;
  }
`;

const Brand = styled.span`
  height: 40px;
  width: 250px;
  display: inline-block;
  overflow: hidden;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 250px auto;
  text-indent: -999px;
`;

class ErrorBoundary extends BugsnagErrorBoundary {
  state = {
    hasError: false,
  };

  static propTypes = {
    coverFullScreen: PropTypes.bool,
    showFallback: PropTypes.bool,
  };

  static defaultProps = {
    showFallback: true,
  };

  componentDidCatch(err, info) {
    super.componentDidCatch(err, info);

    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { coverFullScreen, showFallback } = this.props;

    return hasError && showFallback ? (
      <Wrapper coverFullScreen={coverFullScreen}>
        <Content>
          {coverFullScreen && (
            <a rel="home" href="/">
              <Brand>Travronden Spel</Brand>
            </a>
          )}
          <h1>Ett oväntat fel inträffade</h1>
          <p>
            Prova igen genom att{' '}
            <button
              type="button"
              className="text-button"
              onClick={() => window.location.reload()}
            >
              ladda om
            </button>{' '}
            sidan.
            <br />
            Om problemen kvarstår vänligen{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.trmedia.se/kontakt"
            >
              kontakta supporten
            </a>
            .
          </p>
        </Content>
      </Wrapper>
    ) : (
      super.render()
    );
  }
}

export default ErrorBoundary;
