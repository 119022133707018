import { Anchor } from '@trmediaab/zebra';
import { getHours, isWeekend } from 'date-fns';
import Link from 'redux-first-router-link';

export const LinkItem = props => (
  <Anchor
    variant="matchText"
    fontWeight="semiBold"
    onClick={() => {
      props.setOpen && props.setOpen(false);
    }}
    fontSize={props.fontSize ?? '15px'}
    as={Link}
    css={`
      &:hover,
      &:focus,
      &:active {
        transition: 0;
        color: black !important;
      }
      border-bottom: 2px solid transparent;
      ${props.activeStyles &&
      `
        &:hover, &:focus, &:active {
            border-bottom: 2px solid black;
        }
      `}
      ${props.isActive && 'border-bottom:2px solid black;'}
    `}
    {...props}
  />
);

export const GenerativeLink = ({
  text,
  isActive,
  selectedRound,
  fontSize,
  onClick,
}) => {
  let to = null;

  if (text === 'Tips') {
    to = {
      type: 'ROUND_TIP_START',
      payload: {
        view: 'startlista',
        leg_number: 1,
        bet_type: selectedRound.toLowerCase(),
      },
    };
  }

  if (text === 'Resultat') {
    to = {
      type: 'ROUND_RESULTS',
      payload: { bet_type: selectedRound.toLowerCase() },
    };
  }

  if (text === 'Nyheter') {
    to = {
      type: 'NEWS_LIST',
      payload: { bet_type: selectedRound.toLowerCase() },
    };
  }

  if (text === 'Krönikor') {
    to = {
      type: 'CHRONICLE_LIST',
      payload: { bet_type: selectedRound.toLowerCase() },
    };
  }

  if (text === 'TV') {
    to = {
      type: 'TV_LIST',
      payload: { bet_type: selectedRound.toLowerCase() },
    };
  }

  if (to) {
    return (
      <LinkItem
        to={to}
        key={text}
        isActive={isActive}
        fontSize={fontSize}
        onClick={onClick}
      >
        {text}
      </LinkItem>
    );
  }

  return null;
};

export const isV4Lunch = (betType, startTime) => {
  const date = new Date(startTime);

  return betType === 'V4' && getHours(date) <= 13 && !isWeekend(date);
};
