import { Home, Menu } from '@styled-icons/boxicons-regular';
import { Close } from '@styled-icons/material';
import styled, { css } from 'styled-components';

const iconStyles = css`
  min-width: 25px;
  max-width: 25px;
`;

export const HomeIcon = styled(Home)`
  ${iconStyles}
`;

export const MenuIcon = styled(Menu)`
  ${iconStyles}
  ${props => props.$white && 'color:white;'}
`;

export const CloseIcon = styled(Close)`
  ${iconStyles}
  ${props => props.$white && 'color:white;'}
`;
