import { BorderBox, Box, FlexBox } from '@trmediaab/zebra';
import Link from 'redux-first-router-link';

import logo from 'images/travronden-spel.svg';
import { colors, layout } from 'utils/css-mixins';
import media from 'utils/media';

const Logobar = () => (
  <BorderBox
    as="header"
    bg={colors.primary}
    boxShadow="0 1px 4px 0 rgb(157 157 157 / 50%)"
  >
    <FlexBox
      alignItems="center"
      justifyContent="center"
      boxSizing="content-box"
      maxWidth={`${layout.oneColumn.maxWidth}px`}
      p={`0 ${layout.oneColumn.pageGutter}px`}
      m="0 auto"
      height="50px"
    >
      <Link to={{ type: 'HOME' }}>
        <Box
          as="img"
          width="300px"
          height="auto"
          css={`
            ${media.tablet`
              width: 380px;
            `}
          `}
          src={logo}
          alt="Travronden Spel"
        />
      </Link>
    </FlexBox>
  </BorderBox>
);

export default Logobar;
