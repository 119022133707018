import styled from 'styled-components';

import { colors, layout } from 'utils/css-mixins';
import media from 'utils/media';

export const AppWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props =>
    props.backgroundVariant === 'secondary'
      ? colors.bgsiteSecondary
      : colors.bgsite};

  ${media.tablet`
     background-color: ${props =>
       props.backgroundVariant === 'mediaMixed'
         ? colors.bgsiteSecondary
         : undefined};
  `}
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 0 0 40px;
  margin-left: auto;
  margin-right: auto;

  ${media.desktop`
    display: flex;
    flex-direction: row;
    max-width: ${layout.twoColumn.maxWidth}px;
    padding: 50px ${layout.twoColumn.pageGutter}px 60px;
  `}
`;

export const Main = styled.main`
  min-width: 0;
  flex: 1;
  padding: ${props => (props.hasPanorama ? '0' : '20px')}
    ${layout.oneColumn.pageGutter}px 0;

  ${media.desktop`
    padding: 0;
  `}
`;

export const Aside = styled.aside`
  order: -1;
  padding: 0 ${layout.oneColumn.pageGutter}px;
  ${props =>
    !props.hasPanorama && `border-bottom: solid 5px ${colors.bgsiteSecondary};`}

  ${media.desktop`
    order: initial;
    width: ${layout.asideWidth}px;
    margin-left: ${layout.twoColumn.columnGutters.lg}px;
    border-bottom: none;
    padding: 0;
  `}

  ${media.giant`
    margin-left: ${layout.twoColumn.columnGutters.xl}px;
  `}
`;
