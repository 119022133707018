import {
  CLOSE_INTERVIEW_ARCHIVE_START,
  CLOSE_INTERVIEW_ARCHIVE_SUCCESS,
  FETCH_INTERVIEW_ARCHIVE_FAILURE,
  FETCH_INTERVIEW_ARCHIVE_START,
  FETCH_INTERVIEW_ARCHIVE_SUCCESS,
  LOAD_INTERVIEW,
  LOAD_INTERVIEW_ARCHIVE_CACHE_SUCCESS,
  OPEN_INTERVIEW_ARCHIVE_START,
} from './constants';

export const loadInterview = (slug, leg) => ({
  type: LOAD_INTERVIEW,
  slug,
  leg,
});

export const openInterviewArchiveStart = (
  start_id,
  horse_id,
  race_start_time,
  page,
) => ({
  type: OPEN_INTERVIEW_ARCHIVE_START,
  start_id,
  horse_id,
  race_start_time,
  page,
});

export const closeInterviewArchiveStart = () => ({
  type: CLOSE_INTERVIEW_ARCHIVE_START,
});

export const closeInterviewArchiveSuccess = () => ({
  type: CLOSE_INTERVIEW_ARCHIVE_SUCCESS,
});

export const fetchInterviewArchiveStart = (
  start_id,
  horse_id,
  race_start_time,
  page,
) => ({
  type: FETCH_INTERVIEW_ARCHIVE_START,
  start_id,
  horse_id,
  race_start_time,
  page,
});

export const fetchInterviewArchiveSuccess = (
  start_id,
  horse_id,
  race_start_time,
  page,
  results,
  count,
) => ({
  type: FETCH_INTERVIEW_ARCHIVE_SUCCESS,
  start_id,
  horse_id,
  race_start_time,
  page,
  results,
  count,
});

export const fetchInterviewArchiveFailure = error => ({
  type: FETCH_INTERVIEW_ARCHIVE_FAILURE,
  error,
});

export const loadInterviewArchiveCacheSuccess = (
  start_id,
  horse_id,
  race_start_time,
  page,
) => ({
  type: LOAD_INTERVIEW_ARCHIVE_CACHE_SUCCESS,
  start_id,
  horse_id,
  race_start_time,
  page,
});
