import Link from 'redux-first-router-link';
import styled from 'styled-components';

const StyledLink = styled(({ to, children, className, onClick }) => (
  <Link onClick={onClick} className={className} to={to}>
    {children}
  </Link>
))`
  text-decoration: none;
  color: #369;
`;

export default StyledLink;
