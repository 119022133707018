import { useWindowSize } from '@trmediaab/zebra-hooks';
import { isToday } from 'date-fns';
import { times } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { colors } from 'utils/css-mixins';
import media from 'utils/media';

import RoundButton from './RoundButton';
import { selectError, selectLoading, selectRounds } from './selectors';
import { HEIGHT_MOBILE_DESKTOP, LINEAR_ANIMATION } from './shared';

const Root = styled.section`
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: white;
  overflow-x: scroll;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
  }

  ${HEIGHT_MOBILE_DESKTOP}
`;

const HeadingWrapper = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  text-transform: uppercase;
  background-color: ${colors.bgsiteSecondary};
  min-width: 80px;

  ${media.desktop`
    min-width: 90px;
  `}

  ${HEIGHT_MOBILE_DESKTOP}
`;

const Text = styled.p`
  margin: 0 auto;
  font-size: 10px;
  line-height: 10px;
  text-align: center;

  ${media.desktop`
    font-size: 12px;
    line-height: 13px;
  `}
`;

const RoundWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Placeholder = styled.div`
  width: 200px;
  background-color: #eee;

  ${media.desktop`
    width: 300px;
  `}

  ${HEIGHT_MOBILE_DESKTOP}
`;

const SkeletonAnimation = styled.div`
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${LINEAR_ANIMATION};
  animation-timing-function: linear;
  background: linear-gradient(to right, #eee 10%, #ddd 18%, #eee 33%);
  background-size: 800px 100px;
  position: relative;
  height: 100%;
`;

const NoData = styled.div`
  font-size: 11px;
  font-weight: 600;
  line-height: 1.4;
  color: #4a4a4a;
  padding: 0 10px;
`;

// Logic for sorting the rounds.
// Position [0]: Todays round, can be any round.
// Position [1]: Next V75-round.
// Position [2]: All the other active rounds sorted based on date.
// NOTE: If todays round is a V75-round, return the default array.

const sortRounds = rounds => {
  if (rounds && rounds.some(round => round.bet_type === 'V75')) {
    const firstRound = rounds[0];
    const firstV75 = rounds.find(round => round.bet_type === 'V75');

    return isToday(new Date(firstRound.start_time)) &&
      firstRound.bet_type !== 'V75'
      ? [
          firstRound,
          firstV75,
          ...rounds.slice(1).filter(round => round.slug !== firstV75.slug),
        ]
      : !isToday(new Date(firstRound.start_time)) &&
          firstRound.bet_type !== 'V75'
        ? [firstV75, ...rounds.filter(round => round.slug !== firstV75.slug)]
        : rounds;
  }

  return rounds;
};

const RoundList = ({
  direction,
  setDirection,
  xPosition,
  setMaxLeft,
  setxPosition,
  loading,
  error,
  rounds,
}) => {
  const windowSize = useWindowSize();
  const rootRef = useRef(null);

  useEffect(() => {
    if (rootRef.current !== null) {
      setMaxLeft(rootRef.current.scrollWidth - rootRef.current.clientWidth);

      if (direction !== null) {
        rootRef.current.scrollLeft = xPosition;
        setDirection(null);
      }
    }
  }, [xPosition, windowSize, rounds, setMaxLeft, direction, setDirection]);

  return (
    <Root
      ref={rootRef}
      onScroll={() => setxPosition(rootRef.current.scrollLeft)}
    >
      <HeadingWrapper>
        <Text> Aktuella omgångar</Text>
      </HeadingWrapper>
      <RoundWrapper>
        {loading ? (
          times(5, index => (
            <Placeholder key={index}>
              <SkeletonAnimation></SkeletonAnimation>
            </Placeholder>
          ))
        ) : !rounds || error ? (
          <NoData>Vi har för närvarande inget att visa</NoData>
        ) : (
          sortRounds(rounds).map((round, index) => (
            <RoundButton
              islastchild={index + 1 === rounds.length ? 'true' : undefined}
              key={round.id}
              round={round}
            />
          ))
        )}
      </RoundWrapper>
    </Root>
  );
};

const mapStateToProps = (state, props) => {
  const selectors = {
    loading: selectLoading(state, props),
    error: selectError(state, props),
    rounds: selectRounds(state, props),
  };

  return selectors;
};

RoundList.propTypes = {
  xPosition: PropTypes.number.isRequired,
  setMaxLeft: PropTypes.func.isRequired,
  setxPosition: PropTypes.func.isRequired,
  direction: PropTypes.bool,
  setDirection: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(RoundList);
