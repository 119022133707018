import { createSelector } from 'reselect';

import { createGenericSelector } from '../GenericFetch/selectors';
import { NAMESPACE } from './constants';

export function getPageKey(offset) {
  return `page-${offset / 5}-offset-${offset}`;
}

const getKey = props => props.location.payload.slug;

// Content

const selectChroniclesContent = createGenericSelector(
  NAMESPACE,
  'data',
  props => `chronicle-${getKey(props)}`,
);

const selectNewsArticlesContent = createGenericSelector(
  NAMESPACE,
  'data',
  props => `news_article-${getKey(props)}`,
);

const selectTvArticlesContent = createGenericSelector(
  NAMESPACE,
  'data',
  props => `tv-${getKey(props)}`,
);

export const selectAuthorContent = createSelector(
  [selectChroniclesContent, selectNewsArticlesContent, selectTvArticlesContent],
  (c, n, t) =>
    Object.fromEntries(
      Object.entries({ chronicle: c, news_article: n, tv: t }).filter(
        ([_, section]) => section?.count !== 0 && section,
      ),
    ),
);

export const selectAuthorSharedBets = createGenericSelector(
  NAMESPACE,
  'data',
  props => `sharedBets-${getKey(props)}`,
);

// Error

const selectChroniclesError = createGenericSelector(
  NAMESPACE,
  'error',
  props => `chronicle-${getKey(props)}`,
);

const selectNewsArticlesError = createGenericSelector(
  NAMESPACE,
  'error',
  props => `news_article-${getKey(props)}`,
);

const selectTvArticlesError = createGenericSelector(
  NAMESPACE,
  'error',
  props => `tv-${getKey(props)}`,
);

//Loading

const selectChroniclesLoading = createGenericSelector(
  NAMESPACE,
  'fetching',
  props => `chronicle-${getKey(props)}`,
);

const selectNewsArticlesLoading = createGenericSelector(
  NAMESPACE,
  'fetching',
  props => `news_article-${getKey(props)}`,
);

const selectTvArticlesLoading = createGenericSelector(
  NAMESPACE,
  'fetching',
  props => `tv-${getKey(props)}`,
);

// The loading property is reserved for pager loading state
// Instead, for the initial loading state, we check if we have
// completed all requests
export const selectInitalLoading = createSelector(
  [
    selectChroniclesContent,
    selectNewsArticlesContent,
    selectTvArticlesContent,
    selectChroniclesError,
    selectNewsArticlesError,
    selectTvArticlesError,
  ],
  (
    chronicles,
    newsArticles,
    tvArticles,
    chroniclesError,
    newsError,
    tvError,
  ) => {
    if (chronicles == null && chroniclesError == null) {
      return true;
    }
    if (newsArticles == null && newsError == null) {
      return true;
    }
    if (tvArticles == null && tvError == null) {
      return true;
    }
    return false;
  },
);

export const selectPagerLoading = createSelector(
  [selectChroniclesLoading, selectNewsArticlesLoading, selectTvArticlesLoading],
  (c, n, t) => ({ chronicle: c, news_article: n, tv: t }),
);
