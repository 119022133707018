import { LIVE_FEED_VISIT, LOAD_LIVE_FEED } from './constants';

export function loadLiveFeed(slug) {
  return {
    type: LOAD_LIVE_FEED,
    slug,
  };
}

export function liveFeedVisit(slug) {
  return {
    type: LIVE_FEED_VISIT,
    slug,
  };
}
