import { AUTH_SUCCESS } from './containers/Auth/actions';
import {
  selectIsUserUpdated,
  selectPremium,
  selectUser,
} from './containers/Auth/selectors';
import { NAMESPACE as CLIENT_INFO_NAMESPACE } from './containers/ClientInfo/constants';
import { selectClientInfo } from './containers/ClientInfo/selectors';
import { FETCH_GENERIC_SUCCESS } from './containers/GenericFetch/actions';

let scriptsInjected = false;
let numScriptsLoaded = 0;

function createTriggerbeeUser(user, hasPremium, clientIp) {
  if (user == null) {
    return null;
  }

  const { id, email, first_name, last_name } = user;
  return {
    client_ip: clientIp,
    id,
    email,
    first_name,
    last_name,
    travronden_digital: hasPremium,
    // eslint-disable-next-line camelcase
    name: [first_name, last_name].filter(Boolean).join(' '),
  };
}

function injectScripts() {
  return new Promise((resolve, reject) => {
    if (scriptsInjected) {
      return resolve();
    }

    scriptsInjected = true;

    function onLoaded() {
      numScriptsLoaded++;

      if (numScriptsLoaded === 2) {
        resolve();
      }
    }

    [
      `js?site_id=${process.env.REACT_APP_TRIGGERBEE_KEY}`,
      `js/${process.env.REACT_APP_TRIGGERBEE_KEY}${
        document.location.search.includes('tb-nocache')
          ? `?v=${Date.now()}`
          : ''
      }`,
    ].forEach(path => {
      const s = document.createElement('script');
      s.async = true;
      s.src = `//t.myvisitors.se/${path}`;
      s.addEventListener('load', onLoaded);
      s.addEventListener('error', () => reject(new Error('script load error')));
      s.addEventListener('abort', () =>
        reject(new Error('script load aborted')),
      );
      document.body.append(s);
    });
  });
}

const middleware = store => {
  function handleAuthChange() {
    if (!process.env.REACT_APP_TRIGGERBEE_KEY) {
      console.warn(
        'Triggerbee is enabled but `proccess.env.REACT_APP_TRIGGERBEE_KEY` is not defined',
      );
      return;
    }

    const state = store.getState();

    if (!selectIsUserUpdated(state) || selectClientInfo(state) == null) {
      return;
    }

    const user = selectUser(state);
    const hasPremium = selectPremium(state);
    const { ip: clientIp } = selectClientInfo(state);

    const tbUser = createTriggerbeeUser(user, hasPremium, clientIp);

    window.mtr_site_id = process.env.REACT_APP_TRIGGERBEE_KEY;
    window.triggerbee = window.triggerbee || {};
    window.triggerbee.trMediaUser = tbUser;
    window.mtr_custom = window.mtr_custom || {};

    if (tbUser != null) {
      const tbContact = window.mtr_custom || {};
      tbContact.session = {
        ...tbContact.session,
        ...tbUser,
      };
      window.mtr_custom = tbContact;
    }

    injectScripts()
      .then(() => {
        if (tbUser != null && typeof window.mtr?.goal === 'function') {
          window.mtr.goal('Loggat in');
        }
      })
      .catch(error =>
        console.info(`Failed to inject Triggerbee script - ${error}`),
      );
  }

  return next => async action => {
    if (
      action.type === AUTH_SUCCESS ||
      (action.type === FETCH_GENERIC_SUCCESS &&
        action.model === CLIENT_INFO_NAMESPACE)
    ) {
      const returnValue = next(action);
      handleAuthChange();
      return returnValue;
    }
    return next(action);
  };
};

export { middleware };
