import './utils/polyfills';

import theme from '@trmediaab/theme-travrondenspel';
import { ThemeProvider } from '@trmediaab/zebra';
import ConsentBannerStyles from 'consentBannerStyles';
import {
  registerLocale as registerDatePickerLocale,
  setDefaultLocale as setDefaultDatePickerLocale,
} from 'react-datepicker';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import { AdsLoaderProvider } from 'components/Ad/AdsLoaderContext';
import ErrorBoundary from 'components/ErrorBoundary';
import svLocale from 'utils/svDateLocale';

import Page from './components/Page';
import GlobalCSS from './global-styles';
import store from './store';

registerDatePickerLocale('sv', svLocale);
setDefaultDatePickerLocale('sv');

const root = createRoot(document.querySelector('#root'));

root.render(
  <ThemeProvider theme={theme}>
    <GlobalCSS />
    <ErrorBoundary coverFullScreen>
      <Provider store={store}>
        <ConsentBannerStyles />
        <AdsLoaderProvider>
          <HelmetProvider>
            <Page />
          </HelmetProvider>
        </AdsLoaderProvider>
      </Provider>
    </ErrorBoundary>
  </ThemeProvider>,
);
