import { takeEvery } from 'redux-saga/effects';

import { fetchGeneric } from 'containers/GenericFetch/saga';

import { apiUrl } from '../../utils/request';
import { LOAD_STATISTICS, NAMESPACE } from './constants';

export function* getContent({ entity_type: type, id }) {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`${type}/${id}/statistics`),
    maxAge: Number.POSITIVE_INFINITY,
    key: `${type}-${id}`,
  });
}

export default function* rootSaga() {
  yield takeEvery(LOAD_STATISTICS, getContent);
}
