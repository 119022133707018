import { fnv32a } from '@trmediaab/zebra-utils';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

export const defaultKey = () => 'all';

export const createGenericSelector = (model, property, getKey = defaultKey) =>
  createCachedSelector(
    [(_, props) => getKey(props), state => state.generic[model] || undefined],
    (namespace, substate) =>
      substate && substate[namespace]
        ? substate[namespace][property]
        : undefined,
  )((_, props) => `${model}:${getKey(props)}`);

export const isCached = createSelector(
  [state => state.generic.__cache__, (_, props) => props],
  (cache, { maxAge, url }) => {
    const timestamp = cache[fnv32a(url)];
    const isCached = Boolean(
      timestamp != null && Date.now() < timestamp + maxAge,
    );
    return isCached;
  },
);
