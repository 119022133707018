import config from 'config';
import { getLogoutUrl } from 'containers/Auth/utils';

export const desktopItems = [
  {
    text: 'Hem',
    to: { type: 'HOME' },
  },
  {
    text: 'V75',
    to: {
      type: 'ROUND_TIP_START',
      payload: {
        view: 'startlista',
        leg_number: 1,
        bet_type: 'v75',
      },
    },
  },
  {
    text: 'V86',
    to: {
      type: 'ROUND_TIP_START',
      payload: {
        view: 'startlista',
        leg_number: 1,
        bet_type: 'v86',
      },
    },
  },
  {
    text: 'V64',
    to: {
      type: 'ROUND_TIP_START',
      payload: {
        view: 'startlista',
        leg_number: 1,
        bet_type: 'v64',
      },
    },
  },
  {
    text: 'GS75',
    to: {
      type: 'ROUND_TIP_START',
      payload: {
        view: 'startlista',
        leg_number: 1,
        bet_type: 'gs75',
      },
    },
  },
  {
    text: 'V65',
    to: {
      type: 'ROUND_TIP_START',
      payload: {
        view: 'startlista',
        leg_number: 1,
        bet_type: 'v65',
      },
    },
  },
  {
    text: 'V4',
    to: {
      type: 'ROUND_TIP_START',
      payload: {
        view: 'startlista',
        leg_number: 1,
        bet_type: 'v4',
      },
    },
  },
  {
    text: 'Daniel Berglund',
    to: { type: 'AUTHOR', payload: { slug: 'daniel-berglund' } },
  },
  { text: 'Andelar', to: { type: 'SHARED_BETS' } },
  { text: 'Till start', to: { type: 'ALL_STARTS' } },
];

export const mobileItems = [
  {
    text: 'Hem',
    to: { type: 'HOME' },
  },
  {
    text: 'V75',
    to: {
      type: 'ROUND_TIP_START',
      payload: {
        view: 'startlista',
        leg_number: 1,
        bet_type: 'v75',
      },
    },
  },
  {
    text: 'V86',
    to: {
      type: 'ROUND_TIP_START',
      payload: {
        view: 'startlista',
        leg_number: 1,
        bet_type: 'v86',
      },
    },
  },
  {
    text: 'V65',
    to: {
      type: 'ROUND_TIP_START',
      payload: {
        view: 'startlista',
        leg_number: 1,
        bet_type: 'v65',
      },
    },
  },
  {
    text: 'V4',
    to: {
      type: 'ROUND_TIP_START',
      payload: {
        view: 'startlista',
        leg_number: 1,
        bet_type: 'v4',
      },
    },
  },
  {
    text: 'Daniel Berglund',
    to: { type: 'AUTHOR', payload: { slug: 'daniel-berglund' } },
  },
  { text: 'Andelar', to: { type: 'SHARED_BETS' } },
  { text: 'Till start', to: { type: 'ALL_STARTS' } },
];

export const sideItems = [
  {
    text: 'Om Travronden Spel',
    to: { type: 'ABOUT_PAGE', payload: { slug: 'travronden-spel' } },
  },
  {
    text: 'Annonsera',
    to: {
      type: 'PAGE',
      payload: { slug: 'annonsera' },
    },
  },
  {
    text: 'Kundtjänst',
    to: {
      type: 'PAGE',
      payload: { slug: 'FAQ' },
    },
  },
];

const defaultRoundItems = [
  { text: 'Tips', to: { type: 'ROUND_TIP_START' } },
  { text: 'Resultat', to: { type: 'ROUND_RESULTS' } },
  { text: 'Nyheter', to: { type: 'NEWS_LIST' } },
  { text: 'Krönikor', to: { type: 'CHRONICLE_LIST' } },
  { text: 'TV', to: { type: 'TV_LIST' } },
];

export const extendedItemsMap = {
  HOME: [
    { text: 'Nyheter', to: { type: 'NEWS_LIST' } },
    { text: 'Krönikor', to: { type: 'CHRONICLE_LIST' } },
    { text: 'TV', to: { type: 'TV_LIST' } },
    { text: 'Startlistor & Resultat', to: { type: 'RACE_LIST' } },
  ],
  V75: [...defaultRoundItems],
  V86: [...defaultRoundItems],
  V64: [...defaultRoundItems].filter(
    item => !item.text.includes('Krönikor') && !item.text.includes('TV'),
  ),
  GS75: [...defaultRoundItems].filter(
    item =>
      !item.text.includes('Nyheter') &&
      !item.text.includes('Krönikor') &&
      !item.text.includes('TV'),
  ),
  V65: [...defaultRoundItems].filter(
    item =>
      !item.text.includes('Nyheter') &&
      !item.text.includes('Krönikor') &&
      !item.text.includes('TV'),
  ),
  V4: [...defaultRoundItems].filter(
    item =>
      !item.text.includes('Nyheter') &&
      !item.text.includes('Krönikor') &&
      !item.text.includes('TV'),
  ),
};

export const userDropDownItems = [
  [
    {
      text: 'Läs e-tidningen',
      href: 'https://etidning.travronden.se/',
      external: true,
    },
    {
      text: 'Bevakning',
      href: 'https://www.travronden.se/bevakning/hastar',
      external: true,
    },
  ],
  [
    {
      text: 'Kundtjänst',
      to: {
        type: 'PAGE',
        payload: { slug: 'FAQ' },
      },
    },
    {
      text: 'Mitt TR Media-konto',
      href: config.USERPAGES_URL,
      external: true,
    },
  ],
  [
    {
      text: 'Logga ut',
      href: getLogoutUrl(),
    },
  ],
];
