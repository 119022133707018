import { omitNull } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { Helmet } from 'react-helmet-async';

export const defaultMeta = {
  title: 'Travronden Spel',
  description:
    'Den senaste spelinformationen till alla stora streckspel. V75 tips och V75 resultat. Det har aldrig varit enklare att bli en vinnare!',
  'og:description':
    'Den senaste spelinformationen till alla stora streckspel. V75 tips och V75 resultat. Det har aldrig varit enklare att bli en vinnare!',
  'og:type': 'website',
  'og:title': 'Travronden Spel',
  'og:image': 'https://www.travrondenspel.se/og-default.png',
};

function renderMetaTag(name, value) {
  switch (name) {
    case 'title':
      return (
        <title key={name}>
          {value === defaultMeta.title
            ? defaultMeta.title
            : `${value} · ${defaultMeta.title}`}
        </title>
      );
    case name.startsWith('og:'):
      return <meta key={name} property={name} content={value} />;
    default:
      return <meta key={name} name={name} content={value} />;
  }
}

function parseMeta(meta) {
  // We don't want meta tags with null value
  const pageMeta = omitNull(meta);

  // Mirror title and og:title if either is missing
  if (pageMeta.title != null && pageMeta['og:title'] == null) {
    pageMeta['og:title'] = pageMeta.title;
  } else if (pageMeta.title == null && pageMeta['og:title'] != null) {
    pageMeta.title = pageMeta['og:title'];
  }

  // Mirror description and og:description if either is missing
  if (pageMeta.description != null && pageMeta['og:description'] == null) {
    pageMeta['og:description'] = pageMeta.description;
  } else if (
    pageMeta.description == null &&
    pageMeta['og:description'] != null
  ) {
    pageMeta.description = pageMeta['og:description'];
  }

  return pageMeta;
}

const PageMeta = memo(({ meta = {}, children }) => {
  const pageMeta = parseMeta(meta);
  return (
    <Helmet>
      {Object.keys(pageMeta).map(name => renderMetaTag(name, pageMeta[name]))}
      {children}
    </Helmet>
  );
});

PageMeta.propTypes = {
  meta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    'og:description': PropTypes.string,
    'og:type': PropTypes.string,
    'og:title': PropTypes.string,
    'og:image': PropTypes.string,
  }),
  children: PropTypes.node,
};

export default PageMeta;
