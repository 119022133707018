import { Component, createElement } from 'react';
import styled, { css } from 'styled-components';

import Link from 'components/Link';
import { colors, spacing } from 'utils/css-mixins';
import media from 'utils/media';

const FooterDiv = styled.footer`
  text-align: center;
  background-color: ${colors.bgsiteSecondary};
  padding-bottom: 105px;

  ${media.desktop`
    padding-bottom: 0;
  `}
`;

const SubFooter = styled.div`
  padding: ${props =>
    `${spacing.siteGutter * (props.primary ? 2 : 1)}px ${
      spacing.siteGutter
    }px`};
  background-color: ${props => props.primary && colors.primary};

  ${media.desktop`
    padding-bottom: ${props => props.primary && spacing.siteGutter * 2 - 5}px
  `}
`;

const FooterSubHeader = styled.div`
  margin-bottom: 0.5rem;
  color: ${props => props.textColor};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.375;

  ${media.max('xs')`
    padding-left: ${spacing.siteGutter * 2}px;
    padding-right: ${spacing.siteGutter * 2}px;
  `}

  ${props =>
    props.secondary &&
    `font-weight: 700;
    font-size: 12px;
    margin-bottom: 0;`}
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => props.primary && spacing.siteGutter}px;
  flex-direction: column;

  a {
    color: ${props => (props.primary ? colors.textmeta : colors.bodyText)};
  }

  ${media.tablet`
    margin-top: ${props => props.primary && spacing.siteGutter / 2}px;
    flex-direction: row;
  `}
`;

const LinkStyle = css`
  padding: 5px 10px;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
`;

const Divider = styled.span`
  display: none;
  height: 18px;
  width: 1px;
  background-color: #ddd;
  ${media.tablet`
    display: inline-block;
  `}
`;

const ThinText = styled.span`
  font-weight: 500;
`;

const LinkItem = styled(({ tag = Link, children, ...rest }) =>
  createElement(tag, rest, children),
)`
  ${LinkStyle}
`;

export default class Footer extends Component {
  render() {
    return (
      <FooterDiv {...this.props}>
        <SubFooter primary>
          <FooterSubHeader textColor={colors.white}>
            Travronden Spel är en tjänst från Travronden
          </FooterSubHeader>
          <LinkContainer primary>
            <LinkItem to={{ type: 'NEWS_LIST' }}>Nyheter</LinkItem>
            <LinkItem to={{ type: 'CHRONICLE_LIST' }}>Krönikor</LinkItem>
            <LinkItem to={{ type: 'SHARED_BETS' }}>Andelsspel</LinkItem>
            <LinkItem to={{ type: 'TV_LIST' }}>TV</LinkItem>
            <LinkItem to={{ type: 'ALL_STARTS' }}>Till start</LinkItem>
            <LinkItem
              to={{ type: 'ABOUT_PAGE', payload: { slug: 'travronden-spel' } }}
            >
              Om Travronden Spel
            </LinkItem>
            <LinkItem
              rel="noopener noreferrer"
              href="https://www.trmedia.se/kontakt"
              target="_blank"
              as="a"
            >
              Support
            </LinkItem>
            <LinkItem
              rel="noopener noreferrer"
              href="https://www.trmedia.se/integritetspolicy"
              target="_blank"
              as="a"
            >
              Integritetspolicy
            </LinkItem>
            <LinkItem
              as="a"
              onClick={e => {
                window.OneTrust && window.OneTrust.ToggleInfoDisplay();
                e.preventDefault();
              }}
            >
              Cookie-inställningar
            </LinkItem>
          </LinkContainer>
        </SubFooter>

        <SubFooter>
          <FooterSubHeader textColor={colors.text} as="span" secondary>
            Vill du ha koll på ditt spelande?
          </FooterSubHeader>
          <LinkContainer>
            <LinkItem
              rel="noopener noreferrer"
              href="https://stodlinjen.se"
              target="_blank"
              as="a"
            >
              Stödlinjen<ThinText>, www.stodlinjen.se, 020-819 100</ThinText>
            </LinkItem>
            <Divider />
            <LinkItem
              rel="noopener noreferrer"
              href="https://spelpaus.se"
              target="_blank"
              as="a"
            >
              Spelpaus<ThinText>, www.spelpaus.se</ThinText>
            </LinkItem>
            <Divider />
            <LinkItem
              rel="noopener noreferrer"
              href="https://spelfriheten.se"
              target="_blank"
              as="a"
            >
              Spelfriheten<ThinText>, www.spelfriheten.se</ThinText>
            </LinkItem>
          </LinkContainer>
        </SubFooter>
      </FooterDiv>
    );
  }
}
