import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { createGenericSelector } from '../GenericFetch/selectors';
import { NAMESPACE } from './constants';

const getKey = props => props.id || `${props.slug}-${props.race_number}`;

export const selectRace = createGenericSelector(NAMESPACE, 'data', getKey);
export const selectError = createGenericSelector(NAMESPACE, 'error', getKey);
export const selectLoading = createGenericSelector(
  NAMESPACE,
  'fetching',
  getKey,
);

export const selectTrackList = createSelector(selectRace, (races = []) => {
  const trackList = [];

  races.forEach(race => {
    const item = {
      label: race.meet.track_name,
      value: race.meet.track_slug,
    };
    if (trackList.findIndex(obj => obj.value === item.value) === -1) {
      trackList.push(item);
    }

    return true;
  });
  return trackList;
});

export const getVisibleRaces = createCachedSelector(
  selectRace,
  (state, props) => props.query.track,
  (races, track) => {
    let results = races || [];
    if (track && track !== '__null__') {
      results = results.filter(race => race.meet.track_slug === track);
    }
    return results;
  },
)((_, props) => `filter:${props.query.track}`);

export const selectRounds = createSelector(getVisibleRaces, (races = []) => {
  const rounds = [];

  races.forEach(race => {
    race.legs.forEach(leg => {
      const item = {
        id: leg.round_slug,
        bet_type: leg.bet_type,
        label: `${leg.bet_type} ${race.meet.track_name}`,
      };
      if (rounds.findIndex(obj => obj.id === item.id) === -1) {
        rounds.push(item);
      }
    });
  });

  return rounds;
});
