import { WeatherIcon } from '@trmediaab/zebra-icons';
import { formatNumber } from '@trmediaab/zebra-utils';
import { isToday } from 'date-fns';
import PropTypes from 'prop-types';
import { memo } from 'react';
import Link from 'redux-first-router-link';
import styled from 'styled-components';

import dkFlag from 'components/FlagBadge/images/danish-flag.png';
import { isV4Lunch } from 'components/Navbar/utils';
import RoundLink from 'components/RoundLink';
import Time, { RelativeTime } from 'components/Time';
import { betTypeColors, colors } from 'utils/css-mixins';
import media from 'utils/media';

const StyledLink = RoundLink(styled(Link)`
  width: ${props => (props.splitted ? '235px' : '200px')};
  margin: auto 0;

  ${props =>
    !props.islastchild &&
    `
     border-right: 1px solid ${colors.border};
  `}

  ${media.desktop`
    width: ${props => (props.splitted ? '310px' : '300px')};
  `}
`);

const StyledInner = styled.div`
  padding: 5px;
  display: flex;

  ${media.desktop`
    padding: 10px;
  `}
`;

export const StyledBetType = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: white;
  height: 45px;
  width: 45px;
  border-radius: 3px;
  padding: 5px 0;

  ${media.desktop`
      height: 50px;
      width: 50px;
      font-size: 14px;
  `}

  ${props =>
    props.spacing &&
    `
    margin: 0 5px;
  `}

  ${props =>
    props.isTodaysDate &&
    `
    line-height: 15px;
  `}

  ${props =>
    props.dkBranded
      ? 'background: #5CA8DB'
      : `
        background: linear-gradient(0deg, ${
          betTypeColors(props.betType).from
        } 0, ${betTypeColors(props.betType).to})
  `}
`;

const Text = styled.span`
  ${props => props.bold && 'font-weight: 700;'}
  ${props => props.small && 'font-size:12px;'}
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
`;

const LeftCol = styled(Col)`
  margin-left: 10px;
`;

const RightCol = styled(Col)`
  margin-left: auto;
  display: flex;
`;

const OuterWeatherWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.splitted ? 'column' : 'row')};
  height: ${props => (props.splitted ? '100%' : 'auto')};
  opacity: 0.7;

  ${props =>
    props.splitted &&
    `
      justify-content:space-between;
    `}

  ${media.desktop`
    flex-direction: row;
    height:auto;
  `}
`;

const WeatherWrapper = styled.div`
  display: flex;
  align-items: center;

  ${media.desktop`
    margin-left: auto;
  `}
`;

const StyledWeatherTemp = styled.span`
  font-size: 12px;
  font-weight: 700;
`;

const StyledSeparator = styled.div`
  margin: 0 3px;
  font-size: 20px;
  display: none;
  line-height: 1;

  ${media.desktop`
    display:block;
  `}
`;

const StyledTrack = styled.span`
  font-weight: 700;
  font-size: 13px;
`;

const FakeLink = styled.span`
  margin-top: auto;
  text-decoration: underline;
  font-weight: 600;
  font-size: 12px;
`;

const StartTimeWrapper = styled.div`
  margin-top: auto;
  font-size: 12px;
  opacity: 0.8;
  display: none;

  ${media.desktop`
      display:block;
  `}
`;

const StartTime = styled(RelativeTime)`
  margin-top: auto;
`;

const StyledDkFlag = styled.span`
  display: inline-block;
  width: 100%;
  height: 100%;
  background: url(${dkFlag}) no-repeat left center;
  background-size: 100% auto;
  border-radius: 3px;

  ${media.desktop`
    width: 24px;
    height: 16px;
    border-radius: 0;
    box-shadow: 0 2px 4px 0 rgba(136,136,136,0.5);
  `}
`;

const Weather = ({ meets, slug }) => {
  const splitted = meets.length > 1;

  const nodes = meets.map(meet => (
    <WeatherWrapper key={meet.id}>
      <WeatherIcon
        size={['15px', null, null, '20px']}
        mr="1"
        code={meet.weather.weather}
      />
      <StyledWeatherTemp>
        {formatNumber(meet.weather.temperature, {
          maximumFractionDigits: 0,
        })}
        °
      </StyledWeatherTemp>
    </WeatherWrapper>
  ));

  const Separator = <StyledSeparator key={slug}>/</StyledSeparator>;

  return splitted
    ? [...nodes.slice(0, 1), Separator, ...nodes.slice(1)]
    : nodes;
};

const RoundButton = ({
  round: {
    slug,
    country,
    bet_type: betType,
    start_time: startTime,
    track_name: track,
    meets,
  },
  islastchild,
}) => {
  const dkBranded = country === 'DK' && ['V4', 'V5'].includes(betType);
  const date = new Date(startTime);
  const isTodaysDate = isToday(new Date(startTime));
  const splitted = meets.length > 1;
  const hasWeather = meets.every(meet => meet.weather != null);

  return (
    <StyledLink
      to={{
        type: 'ROUND_TIP',
        payload: {
          slug,
          view: 'startlista',
          bet_type: betType.toLowerCase(),
        },
      }}
      islastchild={islastchild}
      splitted={splitted && hasWeather ? 1 : 0}
    >
      <StyledInner>
        {dkBranded ? (
          <StyledDkFlag />
        ) : (
          <StyledBetType
            isTodaysDate={isTodaysDate}
            betType={betType}
            dkBranded={dkBranded}
          >
            <Text bold>{betType}</Text>
            {isTodaysDate && <Text small>Idag</Text>}
            {isV4Lunch(betType, startTime) && <Text small>Lunch</Text>}
          </StyledBetType>
        )}

        <LeftCol>
          <StyledTrack>
            {dkBranded ? `${betType} ` : ''}
            {track}
          </StyledTrack>
          <FakeLink>Gå till omgång</FakeLink>
        </LeftCol>

        <RightCol>
          {hasWeather && (
            <OuterWeatherWrapper splitted={splitted}>
              <Weather meets={meets} slug={slug} />
            </OuterWeatherWrapper>
          )}
          <StartTimeWrapper>
            {isTodaysDate ? (
              <>
                <Text>Spelstopp:</Text> <Time date={date} format="HH:mm" />
              </>
            ) : (
              <StartTime date={date} compact />
            )}
          </StartTimeWrapper>
        </RightCol>
      </StyledInner>
    </StyledLink>
  );
};

RoundButton.propTypes = {
  round: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    country: PropTypes.string,
    bet_type: PropTypes.string.isRequired,
    start_time: PropTypes.string.isRequired,
    track_name: PropTypes.string.isRequired,
  }),
};

export default memo(RoundButton);
