import { createGenericSelector } from '../GenericFetch/selectors';
import { NAMESPACE } from './constants';

const getKey = props => props.slug || props.model;

export function getPageKey({ offset, limit }) {
  return `page-${offset}-${limit}`;
}

export const selectContent = createGenericSelector(NAMESPACE, 'data', getKey);
export const selectError = createGenericSelector(NAMESPACE, 'error', getKey);
export const selectLoading = createGenericSelector(
  NAMESPACE,
  'fetching',
  getKey,
);
