import { all, fork, put } from 'redux-saga/effects';

import WebSocketSaga from 'utils/websocket';

import { initApp } from './containers/Auth/actions';
import AuthSaga from './containers/Auth/saga';
import AuthorSaga from './containers/AuthorPage/saga';
import ClientInfoSaga from './containers/ClientInfo/saga';
import ContentSaga from './containers/Content/saga';
import FrontpageSaga from './containers/Frontpage/saga';
import InterviewSaga from './containers/Interview/saga';
import KeyStartRaceSaga from './containers/KeyStartRace/saga';
import LiveFeedSaga from './containers/LiveFeed/saga';
import PreliminaryEquipmentsSaga from './containers/PreliminaryEquipments/saga';
import PromoSaga from './containers/Promo/saga';
import RaceSaga from './containers/Race/saga';
import RoundSaga from './containers/Round/saga';
import RoundLegSaga from './containers/RoundLeg/saga';
import SharedBets from './containers/SharedBets/saga';
import Start from './containers/Start/saga';
import StartStatistics from './containers/StartStatistics/saga';
import StatisticsSaga from './containers/Statistics/saga';

function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(ClientInfoSaga),
    fork(PreliminaryEquipmentsSaga),
    fork(RaceSaga),
    fork(RoundSaga),
    fork(RoundLegSaga),
    fork(ContentSaga),
    fork(LiveFeedSaga),
    fork(StatisticsSaga),
    fork(StartStatistics),
    fork(Start),
    fork(InterviewSaga),
    fork(KeyStartRaceSaga),
    fork(WebSocketSaga),
    fork(FrontpageSaga),
    fork(PromoSaga),
    fork(SharedBets),
    fork(AuthorSaga),
  ]);

  // App initialized
  // Global API-calls like the rounds in left sidebar listens to this action.
  yield put(initApp());
}

export default rootSaga;
