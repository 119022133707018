import { isPlainObject } from 'lodash';
import { all, takeEvery } from 'redux-saga/effects';

import { apiUrl } from '../../utils/request';
import { fetchGeneric } from '../GenericFetch/saga';
import { NAMESPACE } from './constants';
import { getPageKey } from './selectors';

const articleTypes = ['chronicle', 'news_article', 'tv'];

function transformContent(res, offset, initialLoad = false) {
  if (![isPlainObject(res), Array.isArray(res.results)].every(Boolean)) {
    return res;
  }

  const { results } = res;

  return {
    ...res,
    ...(initialLoad ? { persistantArticle: results[0] } : []),
    [getPageKey(offset)]: results
      .map(item => item.id)
      .slice(initialLoad ? 1 : null),
  };
}

export function* getContentByAuthorSlug({
  payload: { slug },
  offset = 0,
  initialLoad = true,
}) {
  yield all(
    articleTypes.map(type =>
      fetchGeneric({
        namespace: NAMESPACE,
        url: apiUrl(`content`, {
          author_slug: slug,
          type,
          limit: 6,
          show_round: true,
        }),
        key: `${type}-${slug}`,
        responseHandler: res => transformContent(res, offset, initialLoad),
        maxAge: '5minutes',
      }),
    ),
  );
}

export function* pager({ payload: { slug, offset, type } }) {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`content`, {
      author_slug: slug,
      type,
      limit: 5,
      offset,
      show_round: true,
    }),
    key: `${type}-${slug}`,
    responseHandler: res => transformContent(res, offset),
    maxAge: '5minutes',
  });
}

export function* getSharedBetsBySlug({ payload: { slug } }) {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl('content/shared_bets/travronden_spel', { slug: `${slug}-1` }),
    options: { headers: { SITE: 'andelsspel' } },
    key: `sharedBets-${slug}`,
    maxAge: '5minutes',
  });
}

export default function* rootSaga() {
  yield takeEvery(['AUTHOR'], getContentByAuthorSlug);
  yield takeEvery(['AUTHOR'], getSharedBetsBySlug);
  yield takeEvery(['AUTHOR-PAGER'], pager);
}
