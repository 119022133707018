import { eventChannel } from 'redux-saga';
import { fork, put, take } from 'redux-saga/effects';

import config from 'config';

import { SocketClient } from './client';

export const WS_SUBSCRIBE = 'websocket/SUBSCRIBE';

// subscribe action
export const subscribe = channel => ({
  type: WS_SUBSCRIBE,
  channel,
});

function* watchMessages(socket) {
  const evc = eventChannel(emit => {
    socket.message(msg => {
      emit(msg);
    });
    return () => {
      console.info('stop...');
    };
  });

  while (true) {
    const payload = yield take(evc);
    yield put(payload);
  }
}

export default function* establishWSConSaga() {
  const socket = new SocketClient(config.WEBSOCKET);
  yield fork(watchMessages, socket);

  while (true) {
    const { channel } = yield take(WS_SUBSCRIBE);
    socket.subscribe(channel);
  }
}
