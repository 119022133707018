import baseLocale from 'date-fns/locale/sv';

const originalFormatRelative = baseLocale.formatRelative;

const compactFormatRelativeLocale = {
  lastWeek: 'd MMM',
  yesterday: "'Igår'",
  today: "'Idag kl.' p",
  tomorrow: "'Imorgon'",
  nextWeek: 'd MMM',
  other: 'd MMM',
};

/**
 * Custom sv locale that adds compact relative formatting option
 */
const svDateLocale = {
  ...baseLocale,
  formatRelative: (token, date, baseDate, options) =>
    options.compact
      ? compactFormatRelativeLocale[token]
      : originalFormatRelative(token, date, baseDate, options),
};

export default svDateLocale;
