import { BorderBox, Box } from '@trmediaab/zebra';
import PropTypes from 'prop-types';

import media from 'utils/media';

import { TABLET_BREAKPOINT } from '../constants';

const BarContainer = ({
  children,
  boxShadow,
  borderTop,
  borderTopColor,
  display,
}) => (
  <BorderBox
    bg="white"
    width="100%"
    height="45px"
    overflowX={['scroll', 'visible']}
    boxShadow={boxShadow}
    borderTop={borderTop}
    borderTopColor={borderTopColor}
    display={display}
    css={`
      scrollbar-width: none;
      -ms-overflow-style: none;
      -webkit-overflow-scrolling: touch;
      ::-webkit-scrollbar {
        display: none;
      }
    `}
  >
    <Box
      maxWidth={['none', '1360px']}
      ml="auto"
      mr="auto"
      minWidth="fit-content"
      alignItems="center"
      height="100%"
      position="relative"
      pl={['2', null, '3']}
      pr={['3', null, '3']}
      css={`
        white-space: nowrap;

        > a {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }

        > a:not(:first-child) {
          margin-left: 15px;
        }

        ${media.max(TABLET_BREAKPOINT)`
           :after {
              content: '';
              position: absolute;
              right: 0;
              background: rgb(255, 255, 255);
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 1) 100%
              );
              width: 30px;
              height: 45px;
            }
        `}
      `}
      display="flex"
    >
      {children}
    </Box>
  </BorderBox>
);

BarContainer.propTypes = {
  children: PropTypes.node.isRequired,
  boxShadow: PropTypes.string,
  display: PropTypes.string,
  borderTop: PropTypes.string,
  borderTopColor: PropTypes.string,
};

export default BarContainer;
