import { call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';

import { fetchGeneric } from 'containers/GenericFetch/saga';
import { apiUrl } from 'utils/request';
import { MINUTE, SECOND } from 'utils/time';

import {
  AUTH_REFRESH,
  authRequestSuccess,
  FETCH_OFFERS,
  initApp,
  resetState,
} from './actions';
import { selectUser } from './selectors';
import truth from './truth';

const MAX_REFRESH_DELAY = 10 * MINUTE;

export function* refreshAuthSaga() {
  //yield call(delay, SECOND * 2);
  const user = yield select(selectUser);

  let attempts = 0;
  while (true) {
    attempts += 1;
    const timeout = Math.min(MAX_REFRESH_DELAY, 1.8 ** attempts * SECOND);

    try {
      const response = yield call([truth, 'refreshDebounced']);
      yield put(authRequestSuccess(response.user));
      // The user is logged in and the token is refreshed. All done.
      break;
    } catch (error) {
      if (error.status === 401 || error.status === 403) {
        // The saved token is invalid or expired. Reset everything.
        yield put(authRequestSuccess());
        if (user != null) {
          yield put(resetState());
          yield put(initApp());
          yield put({ type: 'HOME' });
        }
        break;
      }

      const time =
        timeout < MINUTE
          ? `${(timeout / SECOND).toFixed(2)} seconds`
          : `${(timeout / MINUTE).toFixed(2)} minutes`;
      console.warn(
        `Token refresh on startup failed. Retrying in ${time}`,
        error,
      );
    }

    yield delay(timeout);
  }
}

function* fetchOffersSaga() {
  yield fetchGeneric({
    namespace: 'Offers',
    url: apiUrl('checkout/offers/travronden_spel', null, 'tsp-api'),
    maxAge: '5minutes',
  });
}

export default function* rootSaga() {
  yield fork(refreshAuthSaga);
  yield takeLatest(AUTH_REFRESH, refreshAuthSaga);
  yield takeLatest(FETCH_OFFERS, fetchOffersSaga);
}
