import { Stack } from '@trmediaab/zebra';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import Dropdown from '../Dropdown';
import { sideItems } from '../items';
import { LinkItem } from '../utils';

const SideItems = ({ setOpen, open }) => (
  <Fragment>
    <Stack
      axis="horizontal"
      between="3"
      ml="auto"
      display={['none', null, null, 'block']}
    >
      {sideItems.map(({ text, to }, index) => (
        <LinkItem key={index} to={to} opacity="0.9" fontWeight="normal">
          {text}
        </LinkItem>
      ))}
    </Stack>
    <Dropdown items={sideItems} open={open} setOpen={setOpen} />
  </Fragment>
);

SideItems.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default SideItems;
