import GoogleTagManager from '@redux-beacon/google-tag-manager';
import queryString from 'query-string';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createMiddleware } from 'redux-beacon';
import { connectRoutes } from 'redux-first-router';
import restoreScroll from 'redux-first-router-restore-scroll';
import createSagaMiddleware from 'redux-saga';

import { RESET_STATE } from './containers/Auth/actions';
import AuthReducer from './containers/Auth/reducer';
import FrontpageReducer from './containers/Frontpage/reducer';
import GenericReducer from './containers/GenericFetch/reducer';
import InterviewArchiveReducer from './containers/Interview/reducer';
import LiveFeedReducer from './containers/LiveFeed/reducer';
import eventsMap, { LOCATION_CHANGED } from './eventsMap';
import routesMap from './routes';
import rootSaga from './sagas';
import { middleware as triggerbeeMiddleware } from './triggerbee';
import websocketReducer from './utils/websocket/reducer';

let gtmMiddleware;

if (process.env.REACT_APP_TAGMANAGER_ENABLED !== 'false') {
  const gtm = GoogleTagManager();
  gtmMiddleware = createMiddleware(eventsMap, gtm);
}

let routerInitialized = false;

const {
  reducer: routerReducer,
  middleware: routerMiddleware,
  enhancer,
} = connectRoutes(routesMap, {
  restoreScroll: restoreScroll(),
  querySerializer: queryString,
  // Disable redux-first-router `document.title`-setting support. We set the
  // title with react-helmet-async instead.
  title: () => {},
  onAfterChange: (dispatch, getState) => {
    if (routerInitialized) {
      const state = getState();
      const { pathname } = state.location;
      dispatch({ type: LOCATION_CHANGED, payload: pathname });
    }
    routerInitialized = true;
  },
}); // yes, 3 redux aspects

const appReducer = combineReducers({
  location: routerReducer,
  auth: AuthReducer,
  generic: GenericReducer,
  interviewArchive: InterviewArchiveReducer,
  liveFeed: LiveFeedReducer,
  feed: FrontpageReducer,
});

const rootReducer = (passedState, action) => {
  let state =
    action.type === RESET_STATE
      ? { location: passedState.location, auth: passedState.auth }
      : passedState;

  state = websocketReducer(state, action);

  return appReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware(
  process.env.NODE_ENV === 'development'
    ? { sagaMonitor: window['__SAGA_MONITOR_EXTENSION__'] }
    : {},
);

const middlewares = applyMiddleware(
  ...[
    routerMiddleware,
    sagaMiddleware,
    gtmMiddleware,
    process.env.REACT_APP_TRIGGERBEE_ENABLED !== 'false' &&
      triggerbeeMiddleware,
  ].filter(Boolean),
);

const composeEnhancers =
  process.env.NODE_ENV === 'development' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

/**
 * TODO:
 * Refactor reducers and sags to manageable files
 *
 * https://gist.github.com/markerikson/dc6cee36b5b6f8d718f2e24a249e0491
 */
const configureStore = () => {
  const store = createStore(
    rootReducer,
    composeEnhancers(enhancer, middlewares),
  );

  return store;
};

const store = configureStore();

sagaMiddleware.run(rootSaga);

// redux-first-router immediately fires an action for the current page based on
// the startup URL, but this seems to happen before the sagas get to run. As a
// workaround, re-fire the action so that sagas get a chance to run.
store.dispatch(store.getState().location);

export default store;
