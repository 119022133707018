import { takeLatest } from 'redux-saga/effects';

import { fetchGeneric } from 'containers/GenericFetch/saga';

import { apiUrl } from '../../utils/request';
import { CAMPAIGN_NAMESPACE } from './constants';

// TODO - fetch campaigns from checkout api?
function* fetchCampaigns() {
  try {
    yield fetchGeneric({
      namespace: CAMPAIGN_NAMESPACE,
      url: apiUrl(`content`, { limit: 1, type: 'campaign' }),
    });
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield takeLatest('INIT_APP', fetchCampaigns);
}
