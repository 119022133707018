import { createSelector } from 'reselect';

import { createGenericSelector } from 'containers/GenericFetch/selectors';

import config from '../../config';

const baseSelector = state => state.auth;

export const selectUser = createSelector(baseSelector, s => s.user);

const site = 'travronden_spel';
export const selectRoles = createSelector(selectUser, u =>
  u != null && u.groups && u.groups[site] ? u.groups[site] : [],
);

export const selectPremium = config.PREMIUM_TIER_ENABLED
  ? createSelector(selectRoles, roles =>
      roles.some(r => ['admin', 'editor', 'premium', 'live'].includes(r)),
    )
  : () => true;

export const selectLive = config.LIVE_TIER_ENABLED
  ? createSelector(selectRoles, roles =>
      roles.some(r => ['admin', 'editor', 'live'].includes(r)),
    )
  : selectPremium;

export const selectIsUserUpdated = createSelector(
  baseSelector,
  state => state.userUpdated,
);

export const selectOffersData = createGenericSelector('Offers', 'data');

export const selectOffersError = createGenericSelector('Offers', 'error');

export const selectOffersLoading = createGenericSelector('Offers', 'fetching');

/* export const selectOffers = createSelector(
  [selectOffersData, selectOffersError, selectOffersLoading],
  ([data, error, loading]) => ({ data, error, loading }),
);
 */
