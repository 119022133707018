import createReducer from '@ikhsaan/create-reducer';
import { fnv32a } from '@trmediaab/zebra-utils';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import Immutable from 'seamless-immutable';

import {
  FETCH_GENERIC_FAILURE,
  FETCH_GENERIC_START,
  FETCH_GENERIC_SUCCESS,
  UPDATE_GENERIC_CACHE,
} from './actions';

const initialState = Immutable({
  __cache__: {},
});

const mergeState = (state, model, key, obj) => {
  const current = state.getIn([model, key]);
  return state.setIn([model, key], current != null ? current.merge(obj) : obj);
};

const Reducer = createReducer(initialState, {
  [FETCH_GENERIC_START](state, { model, key }) {
    return mergeState(state, model, key, {
      error: undefined,
      fetching: true,
    });
  },

  [FETCH_GENERIC_SUCCESS](state, { model, key, data, id }) {
    let currentData = state.getIn([model, key, 'data'], {});
    let nextData = data;

    // Merge current and next results arrays
    if (
      nextData != null &&
      Array.isArray(nextData.results) &&
      Array.isArray(currentData.results)
    ) {
      currentData = Immutable.asMutable(currentData, { deep: true });

      nextData = {
        ...currentData,
        ...nextData,
        results: uniqWith(
          [...nextData.results, ...currentData.results],
          (a, b) => a.id === b.id || isEqual(a, b),
        ),
      };
    }

    const nextState = mergeState(state, model, key, {
      fetching: false,
      data: nextData,
    });

    if (id) {
      return nextState.setIn([model, key, 'extra_id'], id);
    }

    return nextState;
  },

  [FETCH_GENERIC_FAILURE](state, { model, key, error }) {
    return mergeState(state, model, key, {
      fetching: false,
      error,
    });
  },

  [UPDATE_GENERIC_CACHE](state, { url }) {
    // Note: we store a hash of the url to minimize length of state keys
    return state.setIn(['__cache__', fnv32a(url)], Date.now());
  },
});

export default Reducer;
