export const debounce = fn => {
  // Setup a timer
  let timeout;

  // Return a function to run debounced
  return (...args) => {
    // If there's a timer, cancel it
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }

    // Setup the new requestAnimationFrame()
    timeout = window.requestAnimationFrame(() => {
      fn.apply(this, args);
    });
  };
};
