import { Box, Position } from '@trmediaab/zebra';
import { useWindowSize } from '@trmediaab/zebra-hooks';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import RoundNavigation from 'components/Page/RoundNavigation';
import { selectPremium, selectUser } from 'containers/Auth/selectors';
import { selectActive } from 'containers/Round/selectors';
import useWatchScroll from 'hooks/useWatchScroll';
import routesMap from 'routes';

import BarContainer from './BarContainer';
import BrandBar from './BrandBar';
import { ROUND_LINKS, SCROLL_THRESHOLD, TABLET_BREAKPOINT } from './constants';
import ExtendedBar from './ExtendedBar';
import { desktopItems, extendedItemsMap, mobileItems } from './items';
import SideItems from './SideItems';
import UserNavigation from './UserNavigation';
import UserMenu from './UserNavigation/UserMenu';
import { LinkItem } from './utils';

const Navbar = ({
  user,
  rounds,
  currentPage,
  hasPremium,
  parentLink,
  path,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedRound, setSelectedRound] = useState(null);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [refHeight, setRefHeight] = useState(null);
  const navRef = useRef(null);
  const scrollAmount = useWatchScroll({ enabled: true });
  const windowSize = useWindowSize().width;
  const lessThanTablet = windowSize <= TABLET_BREAKPOINT;
  const mainNavItems = lessThanTablet ? mobileItems : desktopItems;
  const selectedLink = selectedRound || parentLink || currentPage;

  const checkPath = path => {
    ROUND_LINKS.map(
      round =>
        path.includes(`/${round.toLowerCase()}`) && setSelectedRound(round),
    );

    ROUND_LINKS.every(round => !path.includes(`/${round.toLowerCase()}`)) &&
      setSelectedRound(null);
  };

  useEffect(() => {
    // When opening the UserMobileMenu you can still scroll the <body>
    // setting the body's overflow to "hidden" prevents that
    document.body.style.overflow = open ? 'hidden' : 'auto';
    if (windowSize > TABLET_BREAKPOINT) {
      setOpen(false);
      setDropDownOpen(false);
    }

    // Prevent scroll-jitter when hiding the ExtendedBar
    if (navRef.current != null) {
      if (
        refHeight === null &&
        !lessThanTablet &&
        extendedItemsMap.hasOwnProperty(selectedLink)
      ) {
        setRefHeight(navRef.current.offsetHeight);
      }

      if (refHeight !== null && refHeight !== navRef.current.offsetHeight) {
        setRefHeight(navRef.current.offsetHeight);
      }
    }

    checkPath(path);
  }, [lessThanTablet, open, path, refHeight, selectedLink, windowSize]);

  return (
    <Fragment>
      <RoundNavigation />
      {scrollAmount >= SCROLL_THRESHOLD.mobile && lessThanTablet && !open && (
        <BrandBar
          user={user}
          hasPremium={hasPremium}
          lessThanTablet={lessThanTablet}
          shouldStick
          open={open}
          onClick={() => setOpen(!open)}
        />
      )}

      {scrollAmount >= SCROLL_THRESHOLD.desktop && !lessThanTablet && (
        <Box width="100%" height={refHeight || 0} />
      )}

      {!open ? (
        <Position
          ref={navRef}
          position={[
            'static',
            null,
            scrollAmount >= SCROLL_THRESHOLD.desktop ? 'fixed' : 'sticky',
          ]}
          top="0"
          width="100%"
          zIndex="999"
        >
          <UserNavigation
            user={user}
            hasPremium={hasPremium}
            lessThanTablet={lessThanTablet}
          />
          <BrandBar
            user={user}
            hasPremium={hasPremium}
            lessThanTablet={lessThanTablet}
            scrollAmount={scrollAmount}
            open={open}
            onClick={() => setOpen(!open)}
          />
          <BarContainer boxShadow="0 0 0.5em rgb(0 0 0 / 50%);">
            {mainNavItems.map(({ text, to }) => (
              <LinkItem
                activeStyles
                to={to}
                onClick={() => {
                  ROUND_LINKS.includes(text)
                    ? setSelectedRound(text)
                    : setSelectedRound(null);
                }}
                key={text}
                isActive={
                  text === selectedRound ||
                  (parentLink === 'HOME' && text === 'Hem' && !selectedRound) ||
                  (!ROUND_LINKS.includes(text) &&
                    currentPage.includes(to?.type) &&
                    text !== 'Daniel Berglund' &&
                    !path.includes('/skribent/daniel-berglund')) ||
                  (text === 'Daniel Berglund' &&
                    path.includes('/skribent/daniel-berglund'))
                }
              >
                {text}
              </LinkItem>
            ))}
            <SideItems
              open={dropDownOpen}
              setOpen={() => setDropDownOpen(!dropDownOpen)}
            />
          </BarContainer>
          {extendedItemsMap.hasOwnProperty(selectedLink) && (
            <ExtendedBar
              selectedLink={selectedLink}
              currentPage={currentPage}
              path={path}
              shouldHide={
                scrollAmount >= SCROLL_THRESHOLD.desktop && !lessThanTablet
              }
              selectedRound={selectedRound}
              setSelectedRound={setSelectedRound}
              rounds={rounds}
            />
          )}
        </Position>
      ) : (
        <UserMenu
          setSelectedRound={round => setSelectedRound(round)}
          rounds={rounds}
          closeMenu={() => setOpen(false)}
        >
          <RoundNavigation closeMenu={() => setOpen(false)} />
          <UserNavigation user={user} hasPremium={hasPremium} />
          <BrandBar
            user={user}
            hasPremium={hasPremium}
            lessThanTablet={lessThanTablet}
            scrollAmount={scrollAmount}
            open={open}
            onClick={() => setOpen(false)}
          />
        </UserMenu>
      )}
    </Fragment>
  );
};

Navbar.propTypes = {
  user: PropTypes.object,
  rounds: PropTypes.array,
  currentPage: PropTypes.string.isRequired,
  parentLink: PropTypes.string,
  path: PropTypes.string.isRequired,
  hasPremium: PropTypes.bool,
};

export default connect(
  createStructuredSelector({
    user: selectUser,
    rounds: selectActive,
    currentPage: state => state.location.type,
    parentLink: state => routesMap[state.location.type]?.parent,
    path: state => state.location.pathname,
    hasPremium: selectPremium,
  }),
)(Navbar);
