import { Spinner } from '@styled-icons/evil';
import styled, { keyframes } from 'styled-components';

const Wrapper = styled.div`
  margin: 2em auto;
  width: 40px;
  height: 40px;
  position: relative;
`;

const animationFrames = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled(Spinner)`
  animation: ${animationFrames};
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const LoadingIndicator = () => (
  <Wrapper>
    <StyledSpinner />
  </Wrapper>
);

export default LoadingIndicator;
