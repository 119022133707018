import createReducer from '@ikhsaan/create-reducer';
import Immutable from 'seamless-immutable';

import { AUTH_SUCCESS } from './actions';
import truth from './truth';

const initialState = Immutable({
  user: truth.user,
  userUpdated: false,
});

const Reducer = createReducer(initialState, {
  [AUTH_SUCCESS](state, { user }) {
    return state
      .set('fetching', false)
      .set('error')
      .set('user', user)
      .set('userUpdated', true);
  },
});

export default Reducer;
