const config = {
  PREMIUM_TIER_ENABLED: process.env.REACT_APP_PREMIUM_TIER_ENABLED === 'true',
  LIVE_TIER_ENABLED: process.env.REACT_APP_LIVE_TIER_ENABLED === 'true',
  USERPAGES_URL: process.env.REACT_APP_USERPAGES_URL,
  BUGSNAG_API_KEY: process.env.REACT_APP_BUGSNAG_API_KEY,
  WEBSOCKET: process.env.REACT_APP_WEBSOCKET,
  AUTH_URL: process.env.REACT_APP_AUTH_URL,
};

export default config;
