import createReducer from '@ikhsaan/create-reducer';
import Immutable from 'seamless-immutable';

import { liveFeedVisit } from 'containers/LiveFeed/actions';
import liveFeedReducer from 'containers/LiveFeed/reducer';

const initialState = Immutable({});

const updateStartProps = (state, data, chain, key, value) => {
  let nextState = state;
  const race = nextState.getIn(chain);
  if (race && race.id && race.id === data.race_id) {
    race.starts.forEach((start, index) => {
      if (start.id === data.start_id) {
        nextState = nextState.setIn(
          // eslint-disable-next-line unicorn/prefer-spread
          chain.concat(['starts', index, 'odds', key]),
          value,
        );
      }
    });
  }
  return nextState;
};

const updateRaceReducer = (state, data, key_prop, value_prop) => {
  let nextState = state;
  if (nextState.Race) {
    Object.keys(nextState.Race).forEach(key => {
      nextState = updateStartProps(
        nextState,
        data,
        ['Race', key, 'data'],
        data[key_prop],
        data[value_prop],
      );
    });
  }

  if (nextState.RoundLeg) {
    Object.keys(nextState.RoundLeg).forEach(key => {
      nextState = updateStartProps(
        nextState,
        data,
        ['RoundLeg', key, 'data', 'race'],
        data[key_prop],
        data[value_prop],
      );
    });
  }
  return nextState;
};

const updateInterviews = (state, data) => {
  let nextState = state;
  if (nextState.Interview) {
    Object.keys(nextState.Interview).forEach(slug => {
      if (data.instance.race_id === nextState.Interview[slug].extra_id) {
        if (data.new) {
          // append to list
          const array = nextState.Interview[slug].data.asMutable();
          array.unshift(data.instance);
          nextState = nextState.setIn(['Interview', slug, 'data'], array);
        } else {
          // update it
          const index = nextState.Interview[slug].data.findIndex(
            item => item.id === data.instance.id,
          );
          if (index > -1) {
            nextState = nextState.setIn(
              ['Interview', slug, 'data', index],
              data.instance,
            );
          }
        }
      }
      return true;
    });
  }
  return nextState;
};

const updateLiveFeed = (state, data) => {
  let nextState = state;
  if (nextState.LiveFeed) {
    Object.keys(nextState.LiveFeed).forEach(slug => {
      if (data.instance.round_slug === slug) {
        if (data.new) {
          // append to list
          const array = nextState.LiveFeed[slug].data.asMutable();
          array.unshift(data.instance);
          nextState = nextState.setIn(['LiveFeed', slug, 'data'], array);
        } else {
          // update it
          const index = nextState.LiveFeed[slug].data.findIndex(
            item =>
              item.id === data.instance.id &&
              item.content_type === data.instance.content_type,
          );
          if (index > -1) {
            nextState = nextState.setIn(
              ['LiveFeed', slug, 'data', index],
              data.instance,
            );
          }
        }
      }
    });
  }
  return nextState;
};

const updateRound = (state, data) => {
  let nextState = state;
  if (nextState.Round) {
    Object.keys(nextState.Round).forEach(slug => {
      if (data.slug === slug) {
        // found it, update the round
        nextState = nextState.setIn(['Round', slug, 'data'], data);
      }
    });
  }
  return nextState;
};

const updateRace = (state, data) => {
  let nextState = state;
  if (nextState.Race) {
    Object.keys(nextState.Race).forEach(key => {
      const race = nextState.Race[key].data;
      if (race && race.id && race.id === data.id) {
        nextState = nextState.setIn(['Race', key, 'data'], data);
      }
    });
  }
  if (nextState.RoundLeg) {
    Object.keys(nextState.RoundLeg).forEach(key => {
      const roundleg = nextState.RoundLeg[key].data;
      if (
        roundleg &&
        roundleg.race &&
        roundleg.race.id &&
        roundleg.race.id === data.id
      ) {
        nextState = nextState.setIn(['RoundLeg', key, 'data', 'race'], data);
      }
    });
  }
  return nextState;
};

const updateStart = (state, data) => {
  let nextState = state;
  if (nextState.Race) {
    Object.keys(nextState.Race).forEach(key => {
      const race = nextState.Race[key].data;
      if (race && race.id && race.id === data.race_id) {
        race.starts.forEach((start, i) => {
          if (start && start.id && start.id === data.id) {
            nextState = nextState.setIn(
              ['Race', key, 'data', 'starts', i],
              data,
            );
          }
        });
      }
    });
  }
  if (nextState.RoundLeg) {
    Object.keys(nextState.RoundLeg).forEach(key => {
      const roundleg = nextState.RoundLeg[key].data;
      if (
        roundleg &&
        roundleg.race &&
        roundleg.race.id &&
        roundleg.race.id === data.race_id
      ) {
        roundleg.race.starts.forEach((start, i) => {
          if (start && start.id && start.id === data.id) {
            nextState = nextState.setIn(
              ['RoundLeg', key, 'data', 'race', 'starts', i],
              data,
            );
          }
        });
      }
    });
  }
  return nextState;
};

const Reducer = createReducer(initialState, {
  ODDS_UPDATE(state, { data }) {
    return {
      ...state,
      generic: updateRaceReducer(state.generic, data, 'provider', 'odds'),
    };
  },
  GAME_PERCENT_UPDATE(state, { data }) {
    return {
      ...state,
      generic: updateRaceReducer(state.generic, data, 'bet_type', 'percent'),
    };
  },
  LIVE_UPDATE(state, { data }) {
    const { type, payload } = state.location;
    const slug = data.instance.round_slug;
    const { generic } = state;
    let { liveFeed } = state;

    if (
      liveFeed &&
      slug &&
      type &&
      type === 'ROUND_TIP' &&
      payload.view === 'live' &&
      payload.slug === slug
    ) {
      liveFeed = liveFeedReducer(liveFeed, liveFeedVisit(slug));
    }

    return {
      ...state,
      liveFeed,
      generic: updateLiveFeed(generic, data),
    };
  },
  INTERVIEW_UPDATE(state, { data }) {
    return {
      ...state,
      generic: updateInterviews(state.generic, data),
    };
  },
  ROUND_UPDATE(state, { data }) {
    return {
      ...state,
      generic: updateRound(state.generic, data),
    };
  },
  START_UPDATE(state, { data }) {
    return {
      ...state,
      generic: updateStart(state.generic, data),
    };
  },
  RACE_UPDATE(state, { data }) {
    // This is not activated in the API yet
    return {
      ...state,
      generic: updateRace(state.generic, data),
    };
  },
});

export default Reducer;
