import PropTypes from 'prop-types';

import BarContainer from '../BarContainer';
import { ROUND_LINKS } from '../constants';
import { extendedItemsMap } from '../items';
import { GenerativeLink, LinkItem } from '../utils';

const ExtendedBar = ({
  selectedLink,
  currentPage,
  shouldHide,
  path,
  selectedRound,
  rounds,
}) => (
  <BarContainer
    borderTop="1px"
    borderTopColor="greys.1"
    display={shouldHide ? 'none' : 'block'}
  >
    {extendedItemsMap[selectedLink].map(({ text, to }) =>
      ROUND_LINKS.includes(selectedLink) ? (
        <GenerativeLink
          isActive={
            (currentPage.includes(to.type) &&
              path.includes(selectedRound.toLowerCase())) ||
            (currentPage === 'ROUND_TIP' && text === 'Tips')
          }
          text={text}
          key={text}
          rounds={rounds}
          selectedRound={selectedRound}
          fontSize="1"
          activeStyles
        />
      ) : (
        <LinkItem
          key={text}
          activeStyles
          to={to}
          isActive={currentPage.includes(to.type) && !selectedRound}
          fontSize="1"
        >
          {text}
        </LinkItem>
      ),
    )}
  </BarContainer>
);

ExtendedBar.propTypes = {
  selectedLink: PropTypes.string,
  currentPage: PropTypes.string,
  shouldHide: PropTypes.bool,
  path: PropTypes.string,
  selectedRound: PropTypes.string,
  rounds: PropTypes.array,
};

export default ExtendedBar;
