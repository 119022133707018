export const NAMESPACE = 'Interview';

export const LOAD_INTERVIEW = 'Interview/LOAD_INTERVIEW';

export const OPEN_INTERVIEW_ARCHIVE_START =
  'Interview/OPEN_INTERVIEW_ARCHIVE_START';
export const CLOSE_INTERVIEW_ARCHIVE_START = 'Interview/CLOSE_INTERVIEW_START';
export const CLOSE_INTERVIEW_ARCHIVE_SUCCESS =
  'Interview/CLOSE_INTERVIEW_SUCCESS';

export const LOAD_INTERVIEW_ARCHIVE_CACHE_SUCCESS =
  'Interview/LOAD_INTERVIEW_ARCHIVE_CACHE_SUCCESS';

export const FETCH_INTERVIEW_ARCHIVE_START =
  'Interview/FETCH_INTERVIEW_ARCHIVE_START';
export const FETCH_INTERVIEW_ARCHIVE_SUCCESS =
  'Interview/FETCH_INTERVIEW_ARCHIVE_SUCCESS';
export const FETCH_INTERVIEW_ARCHIVE_FAILURE =
  'Interview/FETCH_INTERVIEW_ARCHIVE_FAILURE';
