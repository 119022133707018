import { call } from 'redux-saga/effects';

import { apiUrl } from '../../utils/request';
import { fetchGeneric } from '../GenericFetch/saga';
import { NAMESPACE } from './constants';

function* clientInfoSaga() {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl('client-info', undefined, 'tsp-api'),
    maxAge: Number.POSITIVE_INFINITY,
  });
}

export default function* rootSaga() {
  yield call(clientInfoSaga);
}
