import { Box, Button, Position } from '@trmediaab/zebra';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { CloseIcon, MenuIcon } from '../navIcons';
import { LinkItem } from '../utils';

const Dropdown = ({ items, open, setOpen }) => {
  useEffect(() => {
    window.addEventListener('scroll', () => open && setOpen());
  }, [open, setOpen]);

  return (
    <>
      {open && (
        <Position.fixed
          width="100%"
          height="100%"
          zIndex="9999"
          top="0"
          left="0"
          onClick={setOpen}
          onMouseDown={setOpen}
          onTouchStart={setOpen}
        />
      )}

      <Position.relative
        onClick={setOpen}
        pl="3"
        display={['none', null, 'flex', 'none']}
        alignItems="center"
      >
        <Button>{open ? <CloseIcon /> : <MenuIcon />}</Button>

        {open && (
          <Position.absolute zIndex="99999" top="35px" width="175px" bg="white">
            <Box
              display="flex"
              flexDirection="column"
              css={`
                box-shadow: rgb(172 172 172 / 50%) 0px 2px 4px;

                a:not(:last-child) {
                  border-bottom: 1px solid #dddddd;
                }
              `}
            >
              {items.map(({ text, to }, index) => (
                <LinkItem key={index} to={to} fontSize="1" pl="3" pb="2" pt="2">
                  {text}
                </LinkItem>
              ))}
            </Box>
          </Position.absolute>
        )}
      </Position.relative>
    </>
  );
};

Dropdown.propTypes = {
  items: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default Dropdown;
